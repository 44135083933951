import React, { Component, createRef } from 'react'
import LocomotiveScroll from 'locomotive-scroll';
import styled from 'styled-components';
import { gsap, TweenLite } from "gsap";
import { SplitText } from "gsap/SplitText";
import {Helmet} from "react-helmet";

import Logo from "../assets/logo.inline.svg";
import Leaf from "../assets/leaf.inline.svg";
import image_1 from "../assets/about_us.jpg";
import image_2 from "../assets/aims.jpg";
import image_3 from "../assets/alternative_education.jpg";
import image_4 from "../assets/food_poverty.jpg";
import image_5 from "../assets/covid.jpg";
import image_6 from "../assets/contact.jpg";


const Wrapper = styled.section`
font-family:'Quicksand', sans-serif;
line-height:1.5rem;
overflow:hidden;

  .section{

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
    font-family: 'Lato', sans-serif;
    text-transform:uppercase;
    }

    .heading{
    font-size:inherit;
    z-index:10;
    }
  }
`
const Section = styled.section`
  display:flex;
  justify-content:flex-start;
  align-items:center;
  padding:2rem;
  position: relative;
  flex-wrap: wrap;
  width:100vw;
  height:auto;

  @media (min-width:550px){
  min-height:100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  }

  .inner{
    width:100%;
    order:2;
    padding:80px 0 40px 0;


    @media (min-width:550px){
    width:calc(70vw - 4rem);
    order:1;
    padding:0  0 0 4rem;
    }
 
    @media (min-width:1024px){
    padding:0 7rem;
    }

    .heading{
      font-size:2rem;
      line-height:2.4rem;
      color:var(--green);
    }
  }

  .image{
  width:calc(100% + 2rem);
  position: relative;
  padding-bottom: 200%;
  margin-left: -2rem;
  order:1;

    @media (min-width:380px){
      padding-bottom: 100%;
    }
    @media (min-width:550px){
    width:30vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    top:0;
    right:0;
    order:2;
    }

    img{
    height:120%;
    width:calc(100% + 2rem);
    height:100%;
    position: absolute;
    top:0;
    left:0;
    object-fit:cover;

      @media (min-width:550px){
      width:100%; 
      }
    }

  }

  form{
    input,
    textarea{
    width:100%;
    max-width:410px;
    padding:10px;
    margin-bottom:10px;
    border:1px solid rgba(0,0,0,.3);
    position: relative;
    float:left;
    clear:both;
    border-radius:0;
    transition:.25s all cubic-bezier(0.25, 0.00, 0.35, 1.00);

      &:focus{
      outline:none;
      border:1px solid var(--green);
      }
    }
    button{
    position: relative;
    float:left;
    clear:both;
    background:var(--green);
    transition:.25s all cubic-bezier(0.25, 0.00, 0.35, 1.00);
    border:0;
    padding:10px 20px;
    color:var(--white);
    cursor: pointer;

      &:hover{
      background:var(--green-light)
      }

    }
  }
`
const Intro = styled.section`
  font-size:2.5rem;
  line-height:2.5rem;
  color:var(--green);
  display:flex;
  justify-content:center;
  align-items:center;
  width:100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: 500px) {
  font-size:3.5rem;
  line-height:3.5rem;
  }
  
  @media (min-width: 790px) {
    font-size:5rem;
    line-height:4.9rem;
  }

  @media (min-width: 1024px) {
    font-size:6.2rem;
    line-height:6.1rem;
  }
  
  
  @media (min-width: 1250px) {
    font-size:7.9rem;
    line-height:7.5rem;
  }
  @media (min-width: 1470px) {
 
    h1{
    max-width:1000px;
    }
  }

  h1{
  visibility:hidden;

   div{
    opacity:0;
    transform:translateY(80px)
   } 
  }

    
  span{
    color:var(--black);
  }


  .leaf{
  opacity:0.2;
  position:absolute;
  right:0;
  }
`
const Container = styled.div`
top:0;
left:0;
z-index: 10;
position: relative;
transition:.4s all cubic-bezier(0.25, 0.00, 0.35, 1.00);
background:var(--white);

  &.active{
  transform:translateX(644px);
  opacity:0.6;
  }

`
const Menu = styled.div`
position: fixed;
top:0;
left:0;
width:644px;
height: 100vh; /* Fallback for browsers that do not support Custom Properties */
height: calc(var(--vh, 1vh) * 100);
background:var(--green);
z-index:100;
padding:0 2rem;
transform:translateX(-100%);
pointer-events:none;
transition:.4s transform cubic-bezier(0.25, 0.00, 0.35, 1.00);

  @media (min-width:550px){
    padding:0 5rem;
  }

  .menu-inner{
    width:100%;
    height:100%;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
  
    ul{
    padding:0;
    list-style:none;
    position: relative;
    z-index:10;

      li{
      width:100%;

        button{
        text-decoration:none;
        font-family: 'Lato', sans-serif;
        color:var(--white);
        text-transform:uppercase;
        font-size:1.8rem;
        line-height:2.8rem;
        transition:.5s all cubic-bezier(0.25, 0.00, 0.35, 1.00);
        background:none;
        border:0;
        -webkit-appearance:none;
        border-radius:0;
        cursor: pointer;

          @media (min-width:470px){
          font-size:2.8rem;
          line-height:3rem; 
          text-align:left;
          }

          @media (min-width:670px){
             font-size:3rem;
        line-height:4rem; 
        text-align:left;
          }

          &:hover{
          color:var(--green-light)
          }

          &:focus{
          outline:none;
          }

        }

      }
    }
  }

  .leaf{
  position: fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:0;
  opacity:.2;
  transition:1.3s all cubic-bezier(0.25, 0.00, 0.35, 1.00);
  transform:scale(0.5);
  }


  &.active{
  transform:translateX(0);
  pointer-events:all;

    .leaf{
    transform:scale(1)
    }
    
  }

`
const LogoWrap = styled.div`
position:fixed;
top:0;
left:16px;
transition:.4s all cubic-bezier(0.25, 0.00, 0.35, 1.00);
z-index:100;

@media (min-width:1024px){
  top:2rem;
  left:2rem;
}

svg{
  width:60px;

  @media (min-width:1024px){
    width:auto;
  }
}

  &.active{
  transform:translateX(644px);
  }
`
const NavToggle = styled.div`
position:fixed;
width:30px;
height:25px;
top:30px;
right:20px;
cursor: pointer;
display:flex;
z-index:130;
flex-direction:column;
justify-content:space-between;

  @media (min-width:1024px){
    top:calc(50vh - 1.5rem);
    left:2rem;
    right:inherit;
  }


  .line{
  width:30px;
  height:5px;
  background:var(--green);
  transition:.4s all cubic-bezier(0.25, 0.00, 0.35, 1.00);

    &.top{
    top:0;
    transform-origin:top left;
  }
  &.middle{
    top:0.6rem;
  }
  &.bottom{
    top:1.2rem;
    transform-origin:bottom left;
    }

  }

  &.active{
    .line{
    background:var(--white);

      &.middle{
      opacity:0;
      }

      &.top{
      transform:rotate(45deg);
      }
      &.bottom{
      transform:rotate(-45deg);
      }

    }
  }

`
const Name = styled.div`
transition:.4s transform cubic-bezier(0.25, 0.00, 0.35, 1.00);

  &.active{
  transform:translateX(644px);
  }

  .name{
    font-family: 'Lato', sans-serif;
    text-transform:uppercase;
    color:var(--green);
    position: fixed;
    bottom:2rem;
    right:30vw;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform:rotate(180deg) translateX(-50px);
    opacity:0;

    span{
      color:var(--black)
    }
  }
`




export default class Home extends Component {
  constructor(){
    super()
    this.menu = createRef();
    this.navtoggle = createRef();
    this.container = createRef();
    this.logo = createRef();
    this.nameref = createRef();
  }
  componentDidMount(){

    const updateVH = () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    updateVH();

    window.addEventListener('resize', updateVH );
    
    this.scroll = new LocomotiveScroll({
      el: this.container.current,
      smooth: true,
      // smoothMobile: true,
      class:'is_inview'
    });

    this.scroll.on('call', func => {
      
      // if(func === 'headerFunc'){
      //   HeaderFunc();
      // } 
    });


    var tl = gsap.timeline(), 
    introHeading = new SplitText("#introHeading", {type:"lines"}), 
    words = introHeading.lines; //an array of all the divs that wrap each character
    TweenLite.set("#introHeading", {visibility:"visible"});
    tl.to(words, {duration: 0.8, opacity:1, y:0, stagger: 0.4}, "+=0");

    let sectionSplittext = [];
    Array.from(this.container.current.querySelectorAll('section.section .inner')).map((section, index ) => {
      var sectionContentHeading = new SplitText(section.querySelector('.heading'), {type:"words"}), 
          // sectionContent = new SplitText(section.querySelector('p'), {type:"lines"}), 
          words = sectionContentHeading.words;
          // lines = sectionContent.lines;
          sectionSplittext[index] = {id:'#'+section.parentElement.id, words};
    })
  }

  componentWillUnmount(){
    this.scroll.destroy()
  }

  scrollToFunc = (e) =>{
    let offset;
    let targetAttr;
    let target;

    if(this.container.current.clientWidth < 550){
      offset = -100;
      targetAttr = e.target.dataset.target + ' .heading';
      target = this.container.current.querySelector(targetAttr);
      this.scroll.scrollTo(target,{
        offset:offset
      })
      this.toggleNav();
    }else{
      offset = 0;
      targetAttr = e.target.dataset.target;
      target = this.container.current.querySelector(targetAttr);
      this.scroll.scrollTo(target)
      setTimeout(this.toggleNav, 1000);
    }

    
  }
  
  toggleNav = () => {
    if(this.navtoggle.current.classList.contains('active')){
      this.navtoggle.current.classList.remove('active');
    }else{
      this.navtoggle.current.classList.add('active');
    }
  
    if(this.menu.current.classList.contains('active')){
      this.menu.current.classList.remove('active');
    }else{
      this.menu.current.classList.add('active');
    }
    
    if(this.container.current.classList.contains('active')){
      this.container.current.classList.remove('active');
    }else{
      this.container.current.classList.add('active');
    }
  
    if(this.logo.current.classList.contains('active')){
      this.logo.current.classList.remove('active');
    }else{
      this.logo.current.classList.add('active');
    }
  
    if(this.nameref.current.classList.contains('active')){
      this.nameref.current.classList.remove('active');
    }else{
      this.nameref.current.classList.add('active');
    }
  }

  render() {
    return (
      <Wrapper className="wrapper">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Prentice Hill C.I.C</title>
            <meta name="description" content="Prentice Hill is a not-for-profit organisation that works with children and young people to develop a range of interpersonal skills to promote independence and well being." />
            <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <LogoWrap ref={this.logo}>
          <Logo />
        </LogoWrap>

        <NavToggle ref={this.navtoggle}  onClick={this.toggleNav} className="navToggle">
          <div className="line top"></div>
          <div className="line middle"></div>
          <div className="line bottom"></div>
        </NavToggle>

        <Menu ref={this.menu}>
          <div className="menu-inner">
            <ul>
              <li><button onClick={this.scrollToFunc} data-target="#about">About Us</button></li>
              <li><button onClick={this.scrollToFunc} data-target="#aims">Aims</button></li>
              <li><button onClick={this.scrollToFunc} data-target="#education">Alt. Education</button></li>
              <li><button onClick={this.scrollToFunc} data-target="#food-poverty">Food Poverty</button></li>
              <li><button onClick={this.scrollToFunc} data-target="#covid">Covid Response</button></li>
              <li><button onClick={this.scrollToFunc} data-target="#contact">Contact</button></li>
            </ul>
          </div>
          <Leaf className="leaf"/>
        </Menu>

        <Container id="container" ref={this.container} data-scroll-container>
          <Intro data-scroll-call data-scroll-section className="section intro"  data-section="intro">
              <h1 id="introHeading" className="heading" data-scroll>HELLO. <br /> WELCOME TO <span>PRENTICE</span> HILL.</h1>
              <Leaf className="leaf"/>
          </Intro>

          <Section data-scroll-call data-scroll-section id="about" className="section">
            <div className="inner" data-scroll data-scroll-speed="2" data-section="about">
              <h3 className="heading">About Us</h3>
              <p>Prentice Hill is a not-for-profit organisation that works with children and young people to develop a range of interpersonal skills to promote independence and well being.  We are committed to building support networks within the community to improve social conditions and improve health outcomes. </p>
            </div>
            <div id="about-target" className="target"></div>
            <div data-scroll data-scroll-repeat className="image"  >
              <img data-scroll data-scroll-speed="1.5"  src={image_3} alt="Prentice Hill - About Us" />
            </div>
          </Section>

          <Section data-scroll-call data-scroll-section id="aims" className="section">
            <div className="inner"  data-scroll data-scroll-speed="2">
              <h3 className="heading">Aims</h3>
              <p>To work holistically with children and young people and encourage them to be active learners through research and exploration. We pride ourselves on our close working relationships with parents and carers to ensure that families feel supported.</p>
              <p>To address food poverty by redistributing food and other resources within the local community.</p>
              <p>To support individuals/ families or children and young people that require additional support/ resources due to Covid-19.</p>
            </div>
            <div className="image">
              <img src={image_2}  data-scroll data-scroll-speed="0.5" alt="Prentice Hill - About Us" />
            </div>
          </Section>

          <Section data-scroll-call data-scroll-section id="education" className="section">
            <div className="inner"  data-scroll data-scroll-speed="2"   data-section="education">
              <h3 className="heading">Alternative Education</h3>
              <p>Prentice Hill is an alternative education provision for students that have behavioural, emotional and social difficulties. We offer an Independent Living Skills course in a safe environment for students that may have experienced difficulties within a mainstream setting. Our aims are closely aligned with the ‘Every Child Matters’ Framework which enables us to empower students to transfer the skills they have learnt and turn them into real life experiences. We accept children and young people aged 12 – 18 years old.</p>
            </div>
            <div className="image">
              <img  data-scroll data-scroll-speed="1.5" src={image_6} alt="Prentice Hill - About Us" />
            </div>
          </Section>

          <Section data-scroll-call data-scroll-section id="food-poverty" className="section">
            <div className="inner"  data-scroll data-scroll-speed="2"   data-section="food-poverty">
              <h3 className="heading">Food Poverty</h3>
              <p>We work with vulnerable groups that may be experiencing ‘food poverty’. These groups include individuals who are homeless or at risk of homelessness, older people with limited social support, refugees, asylum seekers and families that have low or no income. We are proud to work in partnership with the Feeding Britain Network in Bradford and Keighley with a range of organisations that are committed to ending hunger locally. Key activities include: </p>
              <ul>
                <li>Holiday food programmes</li>
                <li>Weekly drop in for hot meals</li>
                <li>Outreach work with families</li>
                <li>Participation in study to increase understanding of local food poverty</li>
                <li>Active support for food banks and shelters during Covid 19 crisis</li>
              </ul>
            </div>
            <div className="image">
              <img src={image_4}  data-scroll data-scroll-speed="0.5"  alt="Prentice Hill - About Us" />
            </div>
          </Section>

          <Section data-scroll-call data-scroll-section id="covid" className="section">
            <div className="inner"  data-scroll data-scroll-speed="2" data-section="covid">
              <h3 className="heading">Covid-19 Response</h3>
              <p>The Government has directed that we are able to remain open as an alternative education provision however we are no longer able to provide a weekly drop in service. Alternatively, we are now focusing on our outreach services to redistribute resources within the community. Our policy of not allowing visitors on-site, other than in exceptional circumstances, remains in place. All of these actions have been taken to minimise the risk to young people and staff.</p>
              <p>We take very seriously the health and safety of the children and young people who learn with us as well as that of our dedicated staff team. Families and staff with Covid-19 symptoms such as, a high temperature, a dry continuous cough and loss of taste / smell should immediately contact us, stay home and refrain from attending.</p>
            </div>
            <div className="image">
              <img data-scroll data-scroll-speed="1.5"  src={image_5} alt="Prentice Hill - About Us" />
            </div>
          </Section>

          <Section data-scroll-call data-scroll-section  id="contact" className="section">
            <div className="inner"  data-scroll data-scroll-speed="2" data-section="contact">
              <h3 className="heading">Contact</h3>
              <p>We'd love to hear from you. Please drop us a message using the form below. Thanks. </p>
              <form action="https://getform.io/f/00559ae8-0a53-486f-aec1-b631066aa04c" method="POST">
                <input required type="text" name="name" placeholder="Name"/>
                <input required type="email" name="email" placeholder="Email" />
                <textarea required rows="10" name="message" placeholder="Your message" />
                <button type="submit">Send</button>
              </form>
                      
            </div>
            <div className="image">
              <img src={image_1} alt="Prentice Hill - About Us" />
            </div>
          </Section>
        </Container>
        <Name ref={this.nameref}>
          <h2 className="name"><span>Prentice</span> Hill C.I.C</h2>
        </Name>
      </Wrapper>
    )
  }
}
